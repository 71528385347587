<template>
  <div class="section section__lk">
    <div class="section__wrapper section__subsection-wrapper">
      <profile-header/>
      <div class="section__lk-wrapper">
        <profile-menu />
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileMenu from "@/components/profile/menu/ProfileMenu.vue";
import ProfileHeader from "@/components/profile/ProfileHeader.vue";
import {TokenService} from "../../../services/token.service";

export default {
  name: "Profile",
  components: {ProfileHeader, ProfileMenu},
  mounted() {
    this.$parent.init()
    if(this.$isOLdLk()) {
      localStorage.removeItem('access:path')
      window.location.href = '/profile/settings/personal'
    }
  },
  data() {
    return {
      user: JSON.parse(TokenService.getUser())
    }
  }
}
</script>

<style scoped>

</style>