<template>
  <div class="section__lk-menu-mobile d-sm-none">
    <div class="toggle"></div>
    <template v-if="settingsRoutes.indexOf($route.name) > -1">
      <span><img src="/pic/lk-settings.svg">Настройки</span>
      <div class="section__lk-submenu">
        <div><router-link to="/lk/settings" :class="{'active': $route.name === 'ProfileSettings'}">Личные данные</router-link></div>
        <div><router-link to="/lk/company">Данные о компании</router-link></div>
        <div><router-link to="/lk/subscribes">Рассылки и подписки</router-link></div>
      </div>
    </template>
    <template v-if="$route.name === 'Seminars'">
      <span><img src="/pic/lk-seminar.svg">Семинары</span>
    </template>
    <template v-if="$route.name === 'Online' || $route.name === 'Test'">
      <span><img src="/pic/lk-programm.svg">Онлайн программа</span>
    </template>
    <template v-if="$route.name === 'OnlineRequests' || $route.name === 'OfflineRequests'">
      <span><img src="/pic/lk-order.svg">Мои заявки</span>
      <div class="section__lk-submenu">
        <div><router-link to="/lk/requests/online" :class="{'active': $route.name === 'OnlineRequests'}">Онлайн программа</router-link></div>
        <div><router-link to="/lk/requests/offline" :class="{'active': $route.name === 'OfflineRequests'}">Семинары</router-link></div>
      </div>
    </template>
    <template v-if="$route.name === 'OnlineDocs' || $route.name === 'OfflineDocs'">
      <span><img src="/pic/lk-doc.svg">Мои документы</span>
      <div class="section__lk-submenu">
        <div><router-link to="/lk/docs/online" :class="{'active': $route.name === 'OnlineDocs'}">Онлайн программа</router-link></div>
        <div><router-link to="/lk/docs/offline" :class="{'active': $route.name === 'OfflineDocs'}">Семинары</router-link></div>
      </div>
    </template>
    <template v-if="$route.name === 'Ebs'">
      <span><img src="/pic/lk-elib.svg">Электронная библиотека</span>
    </template>
  </div>
</template>

<script>
import roleRoutersMixin from "@/mixins/roleRoutersMixin";

export default {
  name: "ProfileMenuUserMobile",
  mixins: [roleRoutersMixin]
}
</script>

<style scoped>

</style>